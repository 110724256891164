<template>
  <div id="pageTable">
    <v-container grid-list-xl fluid>
      <v-card>
        <v-layout row wrap pa-3>
          <v-flex lg6 md6 sm12>
            <v-text-field
              :disabled="customerId > 0"
              label="Tên đăng nhập"
              v-model="currentCustomer.Username"
              class="input-group--focused"
              :rules="nameRules"
            ></v-text-field>
          </v-flex>
          <v-flex lg6 md6 sm12>
            <v-text-field
              :disabled="!(roleEditable || roleCS)"
              label="Mã khách hàng"
              v-model="currentCustomer.CustomerCode"
              class="input-group--focused"
            ></v-text-field>
          </v-flex>
          <v-flex lg6 md6 sm12>
            <v-text-field
              :disabled="!roleEditable"
              label="Họ"
              v-model="currentCustomer.FirstName"
              class="input-group--focused"
              :rules="requireRules"
            ></v-text-field>
          </v-flex>
          <v-flex lg6 md6 sm12>
            <v-text-field
              :disabled="!roleEditable"
              label="Tên"
              v-model="currentCustomer.LastName"
              class="input-group--focused"
              :rules="requireRules"
            ></v-text-field>
          </v-flex>
          <v-flex lg6 md6 sm12>
            <v-layout row>
              <v-flex lg12>
                <v-text-field
                  :disabled="!roleEditable"
                  label="Điện thoại"
                  v-model="currentCustomer.PhoneNumber"
                  class="input-group--focused"
                  :rules="requireRules"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex lg6 md6 sm12>
            <v-text-field
              :disabled="!roleEditable"
              label="Email"
              v-model="currentCustomer.Email"
              class="input-group--focused"
            ></v-text-field>
          </v-flex>
          <v-flex lg12 md12 sm12 xs12>
            <v-text-field
              :disabled="!roleEditable"
              label="Địa chỉ"
              v-model="currentCustomer.Address"
              class="input-group--focused"
              :rules="requireRules"
            ></v-text-field>
          </v-flex>
          <v-flex lg6 md12 sm12>
            <v-select
              :disabled="!roleEditable"
              label="Kho TQ"
              class="input-group--focused"
              :items="chinaStores"
              v-model="currentCustomer.ChinaStoreID"
              item-text="Name"
              item-value="ID"
            ></v-select>
          </v-flex>
          <v-flex lg6 md12 sm12>
            <v-select
              :disabled="!roleEditable"
              label="Kho VN"
              class="input-group--focused"
              :items="warehouses"
              v-model="currentCustomer.ReceivePlaceID"
              item-text="Name"
              item-value="ID"
            ></v-select>
          </v-flex>
          <v-flex lg4 md4 sm12>
            <v-select
              :disabled="!roleEditable"
              label="Loại dịch vụ"
              :items="serviceTypes"
              v-model="currentCustomer.ServiceType"
              item-text="name"
              item-value="value"
              :rules="requireRules"
            ></v-select>
          </v-flex>
          <v-flex lg4 md4 sm12>
            <v-select
              :disabled="!roleEditable"
              label="Level"
              :items="levels"
              v-model="currentCustomer.LevelID"
              item-text="VIPName"
              item-value="ID"
              :rules="requireRules"
            ></v-select>
          </v-flex>
          <v-flex lg4 md4 sm12>
            <v-select
              :disabled="!roleEditable"
              label="Trạng thái"
              :items="customerStatus"
              v-model="currentCustomer.Status"
              item-text="name"
              item-value="value"
              :rules="requireRules"
            ></v-select>
          </v-flex>
          <v-flex lg4 md4 sm12>
            <v-select
              :disabled="!roleEditable"
              label="Sale"
              :items="staffs"
              v-model="currentCustomer.SaleID"
              item-text="FullName"
              item-value="ID"
            ></v-select>
          </v-flex>
          <v-flex lg4 md4 sm12 v-if="!isDepositOrder">
            <v-select
              :disabled="!roleEditable"
              label="Tiếng Trung"
              :items="staffs"
              v-model="currentCustomer.CustomerServiceID"
              item-text="FullName"
              item-value="ID"
            ></v-select>
          </v-flex>
          <v-flex lg4 md4 sm12>
            <v-select
              :disabled="!roleEditable"
              label="CSKH"
              :items="p_staffs"
              v-model="currentCustomer.BuyerID"
              item-text="FullName"
              item-value="ID"
            ></v-select>
          </v-flex>
          <v-flex lg4 md4 sm12 v-if="isDepositOrder">
          </v-flex>
          <v-flex lg3 md3 sm12>
            <v-switch
              :disabled="!roleEditable"
              v-model="currentCustomer.IsApprovePayment"
              label="Duyệt công nợ"
              class="input-group--focused"
            ></v-switch>
          </v-flex>
          <v-flex lg3 md3 sm12>
            <h-currency-input
              :disabled="!currentCustomer.IsApprovePayment"
              :decimal="0"
              v-model="currentCustomer.MaxDebt"
              label="Công nợ tối đa"
            ></h-currency-input>
          </v-flex>
          <v-flex lg6 md6 sm12></v-flex>
          <v-flex lg12 md12 sm12 xs12>
            <v-switch
              v-model="currentCustomer.IsSpecialFee"
              label="Áp dụng mức phí đặc biệt"
              class="input-group--focused"
            ></v-switch>
          </v-flex>
          <v-flex lg3 md3 sm12>
            <h-currency-input
              :disabled="!currentCustomer.IsSpecialFee"
              :decimal="0"
              v-model="currentCustomer.FeeWeight"
              label="Phí cân nặng"
            ></h-currency-input>
          </v-flex>
          <v-flex lg3 md3 sm12>
            <h-currency-input
              :disabled="!currentCustomer.IsSpecialFee"
              :decimal="0"
              v-model="currentCustomer.FeeVolumn"
              label="Phí khối"
            ></h-currency-input>
          </v-flex>
          <v-flex lg3 md3 sm12>
            <h-currency-input
              :disabled="!currentCustomer.IsSpecialFee"
              :decimal="0"
              v-model="currentCustomer.CostWeight"
              label="Giá gốc cân nặng"
            ></h-currency-input>
          </v-flex>
          <v-flex lg3 md3 sm12>
            <h-currency-input
              :disabled="!currentCustomer.IsSpecialFee"
              :decimal="0"
              v-model="currentCustomer.CostVolume"
              label="Giá gốc khối"
            ></h-currency-input>
          </v-flex>
          <v-flex lg3 md3 sm12>
            <v-switch
              v-model="currentCustomer.IsWeightByVolume"
              label="Tính cân quy đổi"
              class="input-group--focused"
            ></v-switch>
          </v-flex>
          <v-flex lg12 md12 sm12 xs12 v-if="isDepositOrder">
            <v-text-field
              v-model="currentCustomer.SmallpackageEndpoint"
              label="API cập nhật mã vận đơn"
            ></v-text-field>
          </v-flex>
          <v-flex sm12 md12 lg12>
            <v-widget title="Danh sách địa chỉ">
              <div slot="widget-header-action">
                <v-btn small @click="createNewAddress" v-if="currentCustomer.ID > 0">
                    <v-icon color="#0f7896">add</v-icon>
                </v-btn>
              </div>
              <div slot="widget-content">
                <v-data-table 
                  :headers="addressHeader"
                  :items="addressBooks"
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>
                        <v-edit-dialog :return-value.sync="item.Receiver" large lazy persistent @save="onEditAddressItem({ID: item.ID, Receiver: item.Receiver})">
                          <div>{{ item.Receiver }}</div>
                          <template v-slot:input>
                            <v-text-field v-model="item.Receiver" label="Tên người nhận" autofocus></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td>
                        <v-edit-dialog :return-value.sync="item.Phone" large lazy persistent @save="onEditAddressItem({ID: item.ID, Phone: item.Phone})">
                          <div>{{ item.Phone }}</div>
                          <template v-slot:input>
                            <v-text-field v-model="item.Phone" label="Điện thoại" autofocus></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td>
                        <v-edit-dialog :return-value.sync="item.Address" large lazy persistent @save="onEditAddressItem({ID: item.ID, Address: item.Address})">
                          <div>{{ item.Address }}</div>
                          <template v-slot:input>
                            <v-textarea rows="5" v-model="item.Address" label="Địa chỉ" autofocus></v-textarea>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td class="text-center"><v-icon v-if="roleEditable" medium @click="deleteAddressItem(item.ID)">fa-trash-alt</v-icon></td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
            </v-widget>
          </v-flex>
          <v-flex sm12 md12 lg12>
            <v-widget title="Lịch sử giao dịch">
              <div slot="widget-content">
                <hpo-filter
                  :headers="tradeHeader"
                  :viewName="viewName"
                  v-on:applyFilter="applyFilter"
                  v-on:quickFilter="quickFilter"
                ></hpo-filter>
                <v-data-table 
                  :headers="tradeHeader"
                  :items="trades"
                  :server-items-length="total_rows"
                  :options.sync="pagination"
                  :mobile-breakpoint="100"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>
                        {{
                          item.CreatedDate | display_date("DD-MM-YYYY HH:mm:ss")
                        }}
                      </td>
                      <td v-if="!item.RechargeID">{{ item.HContent }}</td>
                      <td v-else><a :href="'/khach-hang/lich-su-nap-tien/' + item.RechargeID" target="blank">{{ item.HContent }}</a></td>
                      <td class="text-end">
                        {{ item.Type == 1 ? "-" : "+"
                        }}{{ item.Amount | currency }}
                      </td>
                      <td>{{ item.TradeType | trade_type }}</td>
                      <td class="text-end">{{ item.MoneyLeft | currency }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
            </v-widget>
          </v-flex>
        </v-layout>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import phonecode from "@/configs/phonecode";
import configs from "@/configs";
import md5 from "md5";
import { trade_type } from "@/commons/hpod";
import VWidget from "@/components/VWidget";
import Filtering from "@/components/Filtering";
export default {
  filters: {
    trade_type,
  },
  components: {
    VWidget,
    "hpo-filter": Filtering,
  },
  data() {
    let dataType = configs.DATA_TYPE;
    return {
      valid: true,
      isDepositOrder: configs.SYSTEM_TYPE != 'order',
      pwdWarning: "Mật khẩu không khớp.",
      password: "",
      rePassword: "",
      customerStatus: configs.CUSTOMER_STATUS_LIST,
      serviceTypes: configs.ORDER_TYPE_LIST,
      requireRules: [(v) => !!v || "Trường dữ liệu không được để trống."],
      nameRules: [
        (v) => !!v || "Trường dữ liệu không được để trống.",
        (v) =>
          (v || "").indexOf(" ") < 0 ||
          "Tên đăng nhập không được chứa dấu cách",
        (v) => !/(?=.*[A-Z])/.test(v) || "Tên đăng nhập không được viết hoa",
        (v) =>
          /^[\x00-\x7F]*$/.test(v) ||
          "Tên đăng nhập chỉ bao gồm chữ cái a-z và số 0-9",
      ],
      addressHeader: [
        {
          text: "Tên người nhận",
          value: "Receiver",
          dataType: dataType["String"],
        },
        {
          text: "Điện thoại",
          value: "Phone",
          dataType: dataType["String"],
        },
        {
          text: "Địa chỉ",
          value: "Address",
          dataType: dataType["String"],
        },
        {
          text: ""
        }
      ],
      tradeHeader: [
        {
          text: "Ngày giờ",
          value: "CreatedDate",
          sortable: true,
          filterable: true,
          quickSearch: false,
          dataType: dataType["Date"],
        },
        {
          text: "Nội dung",
          value: "HContent",
          sortable: true,
          filterable: true,
          quickSearch: true,
          dataType: dataType["String"],
        },
        {
          text: "Số tiền",
          value: "Amount",
          align: "end",
          sortable: true,
          filterable: true,
          quickSearch: false,
          dataType: dataType["Number"],
        },
        {
          text: "Loại giao dịch",
          value: "TradeType",
          sortable: true,
          filterable: true,
          dataType: dataType["Enum"],
          values: configs.TRADE_TYPE,
        },
        {
          text: "Số dư",
          value: "MoneyLeft",
          align: "end",
          sortable: true,
          filterable: true,
          quickSearch: false,
          dataType: dataType["Number"],
        },
      ],
      pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
      items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
      viewName: "historypaywallet",
      filterConditions: [],
      quickFilterConditions: [],
    };
  },
  computed: {
    ...mapState("customer", {
      loading: "loading",
      currentCustomer: "selected",
    }),
    ...mapState({
      trades: (state) => state.historypaywallet.all.data,
      total_rows: (state) => state.historypaywallet.all.total,
      warehouses: (state) => state.commons.stores,
      chinaStores: (state) => state.commons.chinaStores,
    }),
    ...mapState({
      levels: (state) => state.customerLevel.all.data,
      staffs: (state) => state.commons.all_users,
      addressBooks: state => state.addressBook.all.data,
      p_staffs: (state) =>
        _.filter(state.commons.all_users, function(o) {
          return (
            (o.RoleType & configs.ROLE_TYPE.Purchase) ==
            configs.ROLE_TYPE.Purchase
          );
        }),
      cs_staffs: (state) =>
        _.filter(state.commons.all_users, function(o) {
          return (
            (o.RoleType & configs.ROLE_TYPE.CustomerService) ==
            configs.ROLE_TYPE.CustomerService
          );
        }),
    }),
    customerId() {
      return this.$route.params.Pid;
    },
    prefixes() {
      return _.map(phonecode, "dial_code");
    },
    passwordConfirmationRule() {
      return this.password === this.rePassword || this.pwdWarning;
    },
    roleEditable() {
      return (
        (this.$store.state.authentication.user.roletype &
          configs.ROLE_TYPE.Admin) ==
        configs.ROLE_TYPE.Admin
      );
    },
    roleCS() {
      return (
        (this.$store.state.authentication.user.roletype &
          configs.ROLE_TYPE.Purchase) ==
        configs.ROLE_TYPE.Purchase
      );
    },
  },
  watch: {
    pagination: {
      handler: function(val, oldVal) {
        let filterConditions = this.$store.state.commons.filterConditions;
        this.filterConditions = !_.isEmpty(filterConditions)
          ? filterConditions[this.viewName]
          : [];
        this.filter_data();
      },
      deep: true,
    },
    roleEditable() {
      this.$root.$emit("REQUEST_ROOT_OPTION", {
        invisibleSaveButton: !this.roleEditable,
      });
    },
  },
  methods: {
    filter_data: function() {
      var param = {
        pagination: this.pagination,
        search_term: this.search,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions,
      };
      param.filter.push({
        column: "UID",
        value: Number(this.customerId),
        condition: "equal",
      });
      this.$store.dispatch("historypaywallet/getList", param);
    },
    refreshData() {
      if (this.customerId > 0) {
        this.$store.dispatch("customer/getCustomerDetail", this.customerId);
        this.$store.dispatch("addressBook/getList", {
          filter: [{
                column: "UID",
                value: this.customerId,
                condition: "equal"
            }]
        });
        this.filter_data();
      }
    },
    resetPassword() {
      this.currentCustomer.Password = md5("123@123");
      this.$store.dispatch("customer/setCustomerDetail", {
        data: this.currentCustomer,
        id: this.customerId,
        resetPwd: true,
      });
    },
    saveData() {
      if (this.passwordConfirmationRule !== true) {
        window.getApp.$emit("APP_RESOURCE_FAILED", this.pwdWarning);
      }
      if (this.password !== "") {
        this.currentCustomer.Password = md5(this.password);
      }
      this.$store.dispatch("customer/setCustomerDetail", {
        data: this.currentCustomer,
        id: this.customerId,
      });
    },
    applyFilter(filterConditions) {
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      this.pagination = configs.PAGINATION;
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
      this.filterConditions = filterConditions;
      this.filter_data();
    },
    quickFilter(filterConditions) {
      this.quickFilterConditions = filterConditions;
      this.filter_data();
    },
    deleteAddressItem(id) {
      this.$store.dispatch("addressBook/removeDetail", id);
    },
    createNewAddress() {
      this.$store.dispatch("addressBook/setDetail", {
        data: {UID: this.currentCustomer.ID},
        id: 0
      });
    },
    onEditAddressItem(item) {
      this.$store.dispatch("addressBook/setDetail", {
        data: item,
        id: item.ID
      });
    }
  },
  mounted() {
    this.refreshData();
    this.$root.$emit("REQUEST_ROOT_OPTION", {
      invisibleSaveButton: !this.roleEditable,
    });
    this.$root.$on("REQUEST_REFRESH_DATA", this.refreshData);
    this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
    this.$root.$on("REQUEST_CREATE_DATA", this.resetPassword);
    this.$store.dispatch("commons/getAllUsers");
    let pagingReq = _.cloneDeep(configs.PAGINATION);
    pagingReq.sortDesc = [false];
    this.$store.dispatch("commons/getAllStores");
    this.$store.dispatch("commons/getAllChinaStores");
    this.$store.dispatch("customerLevel/getCustomerLevelList", {
      pagination: pagingReq,
    });
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
    this.$root.$off("REQUEST_CREATE_DATA", this.resetPassword);
    this.$root.$off("REQUEST_REFRESH_DATA", this.refreshData);
  },
};
</script>

<style></style>
